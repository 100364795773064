<template>
  <v-card justify="center">
    <input
      ref="fileInput"
      type="file"
      accept="image/*"
      style="display: none"
      @change="changePhoto"
    />
    <v-card-actions class="mt-12">
      <v-tooltip right nudge-top="90" nudge-left="30" dark>
        <template v-slot:activator="{ on }">
          <a class="mx-auto" v-on="on" @click="inputFile">
            <v-avatar class="profile mt-n12" size="130" color="blue lighten-4">
              <v-img :src="photoURL" lazy-src="" />
            </v-avatar>
          </a>
        </template>
        <span>Нажмите чтобы загрузить фото</span>
      </v-tooltip>
    </v-card-actions>

    <v-card-text>
      <h1 class="text-center">{{ firstName }} {{ lastName }}</h1>
    </v-card-text>
    <v-card-text class="text-center">
      <h3>{{ aboutMe }}</h3>
    </v-card-text>
    <v-card-text v-if="user.club" class="text-center">
      <h3 v-if="user.club.creationTime">
        <span style="color: #FB8C00">Член Клуба MetaMorfosi </span>
        <br />
        <span v-if="user.club.differencePayment < 2"
          >Статус активен до {{ statusActive }} <br />Для продления необходимо совершить
          <b>{{ 2 - user.club.differencePayment }}</b>
          {{ 2 - user.club.differencePayment === 1 ? 'покупку' : 'покупки' }}</span
        >
        <span v-else>Автоматическое продление статуса {{ statusActive }}</span>
      </h3>
      <h3 v-else>
        <span style="color: #FB8C00"
          >Чтобы {{ user.club.numberPayments ? 'востановить' : 'получить' }} статус члена Клуба
          MetaMorosi необходимо совершить еще {{ numberRequiredPayments }}
        </span>
      </h3>
      <dialog-cmp class="d-inline-block">
        <template v-slot:nameActivator>
          о клубе
        </template>
        <template v-slot:text>
          <v-card-title>
            <span style="word-break: normal" class="headline">Про Клуб MetaMorfosi</span>
          </v-card-title>
          <v-card-text class="text-justify">
            Клуб MetaMorfosi объединяет самых активных участников нашего проекта.
            <br />
            <br />
            Клуб - это наш способ поблагодарить вас за участие, за приятное общение, за вдохновение
            развивать MetaMorfosi. Это наш способ продолжать собирать вокруг проекта лучшую
            аудиторию умных, творческих, думающих, ответственных за свою жизнь людей..<br />
            <br />
            <b>* Как попасть в Клуб?</b><br />
            Условия предельно простые: вы зарегистрированы на нашем сайте и у вас за это время
            сделано 5 и более любых покупок (студий, календарей, курсов и т.д., кроме бесплатных).
            <br /><br />
            <b>* Как сохранить членство в Клубе? </b><br />
            Совершить не менее 2 покупок в течение следующего календарного года.
            <br />
            <br />
            <b>* Как узнать, что вы уже в Клубе?</b><br />
            Соответствующий статус отображается в вашем личном кабинете на сайте MetaMorosi.
            <br />
            <br />
            <b>* Зачем вступать в Клуб? </b><br />
            - Приоритет - вы первыми узнаете о самом интересном в MetaMorfosi. Это будут письма,
            всегда без спама и всегда по делу.
            <br />⠀ - Всегда действующие клубные цены на все студии, календари и т.д. Это всегда
            самые низкие цены и самые большие скидки. <br />
            - Специальные клубные предложения - студии за муравьеды, студии только для членов Клуба,
            бесплатные студии. <br />
          </v-card-text>
        </template>
      </dialog-cmp>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'AvatarProfile',
  components: {
    DialogCmp: () => import('@/components/shared/dialog/DialogCmp')
  },
  props: {
    firstName: {
      type: String,
      required: true
    },
    lastName: {
      type: String,
      required: true
    },
    aboutMe: {
      type: String,
      required: true
    }
  },
  data: () => ({
    photoURL: ''
  }),
  computed: {
    user() {
      return this.$store.getters.user
    },
    statusActive() {
      return new Date(
        new Date(this.user.club.creationTime).setFullYear(
          new Date(this.user.club.creationTime).getFullYear() + 1
        )
      )
        .toISOString()
        .substr(0, 10)
    },
    numberRequiredPayments() {
      if (this.user.club.numberPayments) {
        return 2 - this.user.club.differencePayment === 1 ? '1 покупку' : '2 покупки'
      } else {
        switch (this.user.club.differencePayment) {
          case 0:
            return '5 покупок'
          case 1:
            return '4 покупки'
          case 2:
            return '3 покупки'
          case 3:
            return '2 покупки'
          case 4:
            return '1 покупку'
          default:
            return ''
        }
      }
    }
  },
  watch: {
    user: {
      deep: true,
      handler() {
        this.photoURL = this.user.photoURL || ''
      }
    }
  },
  created() {
    this.photoURL = this.user.photoURL || ''
  },
  methods: {
    inputFile() {
      this.$refs.fileInput.click()
    },
    changePhoto() {
      const typeFile = [
        'image/tiff',
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/bmp',
        'image/x-icon',
        'image/svg+xml',
        'image/webp'
      ]
      const imageProfile = this.$refs.fileInput.files[0]
      if (imageProfile.size >= 1000000) {
        this.$store.commit('setError', 'Размерфайла не должен превышать 1000КБ')
      } else if (!typeFile.includes(imageProfile.type)) {
        this.$store.commit('setError', 'Неверный тип файла')
      } else {
        this.$store.dispatch('changePhotoUser', imageProfile)
      }
    }
  }
}
</script>

<style scoped />
